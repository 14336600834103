import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';

export class JavaScriptBottom extends Component {
    constructor() {
        super();
        this.state = {
            JavaScriptData: []
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetJavaScript("Bottom")).then(response => {

            this.setState({ JavaScriptData: response.data['Java Script'] });

        }).catch(error => {

        });


    }
    render() {
        const JavaScriptList = this.state.JavaScriptData;
        const JavaScriptView = JavaScriptList.map((JavaScriptList, i) => {
            return <div key={i.toString()}>
                {JavaScriptList.javascript_link}
            </div >

        })
        return (
            <Fragment>
                {JavaScriptView}
            </Fragment>
        )
    }
}

export default JavaScriptBottom
