import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SubscribeForm from '../forms/SubscribeForm'

class Subscribe extends Component {
    render() {
        return (
            <Fragment>
                <div className="subscribe pdtop50-s4">
                    <div className="subscribe-box">
                        <SubscribeForm />
                        <div className="text">
                            <p><span>*</span>Subscribe us and get latest news and updates</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Subscribe
