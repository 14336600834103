import React, { Component } from 'react'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageDownloads extends Component {
    constructor() {
        super();
        this.state = {
            DownloadsData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetDownloads).then(response => {

            this.setState({ DownloadsData: response.data['Downloads'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticSeo("Downloads")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],
                    seo_description: JsonData['seo_description'],
                    seo_keywords: JsonData['seo_keywords'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        const DownloadsList = this.state.DownloadsData;
        const DownloadsView = DownloadsList.map((DownloadsList, i) => {
            return <li key={i.toString()}>
                <a href={DownloadsList.downloads_file} target="_blank"><i className="fa fa-angle-double-right" />{DownloadsList.downloads_name}</a>
            </li>

        })

        return (
            <Fragment>
                <Helmet>
                    <title>{this.state.seo_title}</title>
                    <meta name="description" content={this.state.seo_description} />
                    <meta name="keywords" content={this.state.seo_keywords} />
                    <link rel="canonical" href={this.state.page_name} />
                </Helmet>
                {DownloadsView}
            </Fragment>
        )
    }
}

export default PageDownloads
