import React, { Component } from 'react'
import { Fragment } from 'react'

class MobileDropdown extends Component {

    constructor(props) {
        super();
        this.state = {
            DropDownState: "drop-down-close",
            SubDropDownState: "subdrop-down-close"
        }

    }

    toggleCollapse = (event) => {
        this.DropDownOpenClose();
        event.preventDefault();

    }

    SubtoggleCollapse = (event) => {
        this.SubDropDownOpenClose();
        event.preventDefault();

    }

    DropDownCollapse = () => {
        this.DropDownOpenClose();
    }

    SubDropDownCollapse = () => {
        this.SubDropDownOpenClose();
    }

    DropDownOpenClose = (event) => {
        let DropDownState = this.state.DropDownState;


        if (DropDownState === "drop-down-open") {
            this.setState({ DropDownState: "drop-down-close" })

        }
        else {
            this.setState({ DropDownState: "drop-down-open" })
        }
        event.preventDefault();

    }


    SubDropDownOpenClose = (event) => {
        let SubDropDownState = this.state.SubDropDownState;


        if (SubDropDownState === "subdrop-down-open") {
            this.setState({ SubDropDownState: "subdrop-down-close" })

        }
        else {
            this.setState({ SubDropDownState: "subdrop-down-open" })
        }

        event.preventDefault();
    }




    render() {
        const MenuList = this.props.data;
        const MenuView = MenuList.map((MenuList, i) => {

            return <li className="dropdown" key={i.toString()}><a href={process.env.PUBLIC_URL + "/" + MenuList.page_url} onClick={() => this.toggleCollapse()}>{MenuList.menu_name}</a>
                <ul className={this.state.DropDownState}>
                    {
                        (MenuList.category_name).map((CatList, i) => {

                            if (CatList.category_subcategory.length > 0) {
                                return <li class="dropdown">
                                    <a href={process.env.PUBLIC_URL + "/" + CatList.page_url} onClick={() => this.SubtoggleCollapse()}>{CatList.category_name}</a>
                                    <ul className={this.state.SubDropDownState}>
                                        {
                                            (CatList.category_subcategory).map((SubList, i) => {
                                                return <li>
                                                    <a href={process.env.PUBLIC_URL + "/" + SubList.page_url} >{SubList.subcategory_name}</a>

                                                </li>
                                            })
                                        }
                                    </ul>
                                    <div className="dropdown-btn" onClick={() => this.SubtoggleCollapse()}></div></li>

                            }
                            else {
                                return <li><a href={process.env.PUBLIC_URL + "/" + CatList.page_url} >{CatList.category_name}</a></li>

                            }


                        })

                    }

                </ul>
                <div className="dropdown-btn" onClick={this.toggleCollapse}></div></li>

        });


        return (
            <Fragment>
                {MenuView}
            </Fragment>
        )
    }
}

export default MobileDropdown
