import React, { Component, Fragment } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';
import SliderLoading from '../components/PlaceHolder/SliderLoading';
import PageDetails from '../components/PageDetails/PageDetails';


class Page extends Component {
    constructor({ match }) {
        super();
        this.state = {
            page_url: match.params.page_url,
            PageData: [],
            isLoading: "",
            mainDiv: "d-none"

        }
    }


    componentDidMount() {

        axios.post(AppURL.GetPage(this.state.page_url)).then(response => {

            this.setState({ PageData: response.data, isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {


        if (this.state.mainDiv == "d-none") {

            return (
                <Fragment>
                    <SliderLoading isLoading={this.state.isLoading} />
                </Fragment>
            )

        } else {

            return (
                <Fragment>
                    <PageDetails data={this.state.PageData} />
                </Fragment>
            )

        }


    }
}
export default Page
