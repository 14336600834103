import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import validation from '../../validation/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class HomeForm extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: ""

        }
    }

    nameOnChange = (event) => {
        let name = event.target.value;
        // alert(name);
        this.setState({ name: name });
    }
    emailOnChange = (event) => {
        let email = event.target.value;
        // alert(email);
        this.setState({ email: email })
    }
    phoneOnChange = (event) => {
        let phone = event.target.value;
        // alert(email);
        this.setState({ phone: phone })
    }
    messageOnChange = (event) => {
        let message = event.target.value;
        // alert(message);
        this.setState({ message: message });
    }

    onFormSubmit = (event) => {

        let name = this.state.name;
        let email = this.state.email;
        let phone = this.state.phone;
        let message = this.state.message;
        let sendBtn = document.getElementById('sendBtn');
        let homeForm = document.getElementById('homeForm');

        if (name.length == 0) {
            toast.error("Please Enter Your Name");
        }
        else if (email.length == 0) {
            toast.error("Please Enter Your Email");
        }
        else if (phone.length == 0) {
            toast.error("Please Enter Your Phone Number");
        }
        else if (message.length == 0) {
            toast.error("Please Enter Message");
        }
        else if (!(validation.NameRegx).test(name)) {
            toast.error("Invalid Name");
        }
        else {

            sendBtn.innerHTML = "Sending...";
            let MyFormData = new FormData();
            MyFormData.append("name", name)
            MyFormData.append("email", email)
            MyFormData.append("phone", phone)
            MyFormData.append("message", message)

            axios.post(AppURL.PostContact, MyFormData)
                .then(function (response) {
                    if (response.status == 200 && response.data == 1) {
                        toast.success("Message Send Successfully");
                        sendBtn.innerHTML = "Send Message";
                        homeForm.reset();

                    }
                    else {
                        toast.error("error");
                        sendBtn.innerHTML = "Message Not Sended";
                    }

                })
                .catch(function (error) {
                    toast.error(error);
                    sendBtn.innerHTML = "Message Not Sended";

                });


        }

        event.preventDefault();
    }

    render() {
        return (
            <Fragment>

                <div className='home-form'>
                    <div className="section-title with-desc clearfix">
                        <div className="title-header">
                            <h5>Request a Call Back</h5>
                        </div>
                    </div>

                    <Form id="homeForm" onSubmit={this.onFormSubmit}>

                        <div className='form-group'><input onChange={this.nameOnChange} className="form-control bg-white" type="text" placeholder="Enter Name" /></div>
                        <div className='form-group'><input onChange={this.emailOnChange} className="form-control bg-white" type="email" placeholder="Enter Email" /></div>
                        <div className='form-group'><input onChange={this.phoneOnChange} className="form-control bg-white" type="phone" placeholder="Enter Phone Number" /></div>
                        <div className='form-group'><Form.Control onChange={this.messageOnChange} className="form-control bg-white" as="textarea" rows={3} placeholder="Enter Your Message" /></div>
                        <Button id="sendBtn" type='submit' className="submit-btn"> Send Message</Button>

                    </Form>

                </div>


                <ToastContainer />
            </Fragment>
        )
    }
}

export default HomeForm
