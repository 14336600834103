import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';

export class Whatsapp extends Component {
    constructor(props) {
        super();
        this.state = {
            ContactData: []


        }
    }

    componentDidMount() {

        let contact_title = this.props.contact_title;
        axios.post(AppURL.GetWhatsapp(contact_title)).then(response => {

            this.setState({ ContactData: response.data['Whatsapp'] });

        }).catch(error => {

        });

    }
    render() {
        const WhatsappList = this.state.ContactData;
        const WhatsappView = WhatsappList.map((WhatsappList, i) => {
            return <div key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                    <div className="contact-address-icon-box">
                        <div className="contact-address-icon">
                            <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                <i className="fab fa-whatsapp"></i>
                            </div>
                        </div>
                        <div className="contact-address-content">
                            <div className="contact-address-title">
                                <h5>{WhatsappList.contact_name}</h5>
                            </div>
                            <div className="contact-address-desc">
                                <p><a href={`https://wa.me/{WhatsappList.contact_whtsapp}`} target="_blank">{WhatsappList.contact_whtsapp}</a></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
            </div>
        })
        return (
            <Fragment>

                {WhatsappView}

            </Fragment>
        )
    }
}


export default Whatsapp
