import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import validation from '../../validation/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SubscribeForm extends Component {
    constructor() {
        super();
        this.state = {
            email: ""


        }
    }

    emailOnChange = (event) => {
        let email = event.target.value;
        // alert(email);
        this.setState({ email: email })
    }


    onFormSubmit = (event) => {

        let email = this.state.email;
        let sendBtn = document.getElementById('sendBtn');
        let subscribeForm = document.getElementById('subscribeForm');

        if (email.length == 0) {
            toast.error("Please Enter Your Email");
        }

        else {

            sendBtn.innerHTML = "Sending...";
            let MyFormData = new FormData();
            MyFormData.append("email", email)


            axios.post(AppURL.PostSubscribe, MyFormData)
                .then(function (response) {
                    if (response.status == 200 && response.data == 1) {
                        toast.success("Message Send Successfully");
                        sendBtn.innerHTML = "Send";
                        subscribeForm.reset();

                    }
                    else {
                        toast.error("error");
                        sendBtn.innerHTML = "Message Not Sended";
                    }

                })
                .catch(function (error) {
                    toast.error(error);
                    sendBtn.innerHTML = "Message Not Sended";

                });


        }

        event.preventDefault();
    }

    render() {
        return (
            <Fragment>
                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                    <Form id="subscribeForm" onSubmit={this.onFormSubmit} className="subscribe-form">
                        <input onChange={this.emailOnChange} className="form-control bg-white" type="email" placeholder="Your Email Address" class="subscribe-input" />
                        <Button id="sendBtn" type='submit' class="btn btn--yellow btn--background"> Send</Button>
                    </Form>

                    <ToastContainer />
                </Col>

            </Fragment>
        )
    }
}

export default SubscribeForm
