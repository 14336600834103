import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col, Button, Form, Card, Modal } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';
import validation from '../validation/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class BlogDetails extends Component {
    constructor({ match }) {
        super();
        this.state = {
            HomeBlogData: [],
            CommentsData: [],
            blog_url: match.params.blog_url,
            ReviewModal: false,
            CommentsModal: false,
            show: false,

            name: "",
            email: "",
            message: "",
            blog_name: ""

        }
    }



    ReviewModalOpen = (event) => {
        this.setState({ ReviewModal: true });
    };

    ReviewModalClose = () => {
        this.setState({ ReviewModal: false })
    };

    CommentsModalOpen = (event) => {
        this.setState({ CommentsModal: true });
    };

    CommentsModalClose = () => {
        this.setState({ CommentsModal: false })
    };


    nameOnChange = (event) => {
        let name = event.target.value;
        this.setState({ name: name });
    }
    emailOnChange = (event) => {
        let email = event.target.value;
        this.setState({ email: email })
    }
    blogOnChange = (event) => {
        let blog_name = event.target.value;
        this.setState({ blog_name: blog_name })
    }
    urlOnChange = (event) => {
        let blog_url = event.target.value;
        this.setState({ blog_url: blog_url })
    }
    messageOnChange = (event) => {
        let message = event.target.value;
        this.setState({ message: message });
    }

    PostComment = (event) => {

        let name = this.state.name;
        let email = this.state.email;
        let message = this.state.message;
        let blog_name = this.state.blog_name;
        let blog_url = this.state.blog_url;
        let commentBtn = document.getElementById('commentBtn');
        let commentForm = document.getElementById('commentForm');


        if (name.length == 0) {
            toast.error("Please Enter Your Name");
        }
        else if (email.length == 0) {
            toast.error("Please Enter Your Email");
        }
        else if (blog_name.length == 0) {
            toast.error("Please Select Your Blog");
        }
        else if (blog_url.length == 0) {
            toast.error("Please Select Your Blog Url");
        }
        else if (message.length == 0) {
            toast.error("Please Enter Your Message");
        }
        else if (message.length > 50) {
            toast.error("Comments can't more then 150 character");
        }
        else if (!(validation.NameRegx).test(name)) {
            toast.error("Invalid Name");
        }
        else {

            let MyFormData = new FormData();
            MyFormData.append("name", name)
            MyFormData.append("email", email)
            MyFormData.append("blog_name", blog_name)
            MyFormData.append("blog_url", blog_url)
            MyFormData.append("message", message)


            axios.post(AppURL.PostBlogComments, MyFormData)
                .then(function (response) {
                    if (response.status == 200 && response.data == 1) {
                        toast.success("Message Send Successfully");
                        commentBtn.innerHTML = "Send Message";
                        commentForm.reset();

                    }
                    else {
                        toast.error("Message Not Sended ! Try Aagain");
                        commentBtn.innerHTML = "Message Not Sended ! Try Aagain";
                    }

                })
                .catch(function (error) {
                    toast.error(error);
                    commentBtn.innerHTML = "Message Not Sended ! Try Aagain";

                });


        }

        event.preventDefault();


    }



    componentDidMount() {

        axios.post(AppURL.GetBlog(this.state.blog_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Blog'])[0];
                this.setState({
                    blog_name: JsonData['blog_name'],
                    blog_image: JsonData['blog_image'],
                    blog_text: JsonData['blog_text'],
                    blog_time: JsonData['blog_time'],
                    blog_view: JsonData['blog_view']
                });
            }

        }).catch(error => {

        });



        axios.post(AppURL.BlogCommentsList(this.state.blog_url)).then(response => {

            this.setState({ CommentsData: response.data['Comments'] });

        }).catch(error => {

        });



    }

    render() {

        const CommentsList = this.state.CommentsData;
        const CommentsView = CommentsList.map((CommentsList, i) => {
            return <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} key={i.toString()}>
                <h5 className='font-14'><span className='fa fa-user'></span>{CommentsList.name}</h5>
                <p className='font-12'><span className='fa fa-pencil'></span>{CommentsList.message}</p>
                <hr></hr>
            </Col>

        })




        return (
            <Fragment>



                <Breadcrumb className="page-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="content-box">
                                    <div className="content-wrapper">
                                        <div className="title">
                                            <h1>{this.state.blog_name}</h1>
                                            <p>{this.state.blog_time}</p>
                                        </div>
                                        <ul className="bread-crumb">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home </a></li>
                                            <li className="active">{this.state.blog_name}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>


                <section className='inner-pages-details'>
                    <Container>
                        <Row>
                            <Col lg={8} md={8} xl={8} xxl={8} sm={12} xs={12}>
                                <img src={this.state.blog_image} alt="" className='img-fluid' />
                                <div className="post-meta">
                                    <span className="ttm-meta-line"><i className="fa fa-comments" />{this.state.blog_url} Comments</span>
                                    <span className="ttm-meta-line"><i className="fa fa-eye" />{this.state.blog_view} Views</span>
                                    <span className="ttm-meta-line"> Share:  <a href={`https://wa.me/${this.state.blog_url}`} target="_blank"><i className="fab fa-whatsapp" /></a>  <i className="fab fa-facebook" /> <i className="fab fa-twitter" /> <i className="fab fa-instagram" /></span>

                                </div>

                                <h1>{this.state.blog_name}</h1>

                                <div dangerouslySetInnerHTML={{ __html: this.state.blog_text }} />

                                <Button onClick={this.ReviewModalOpen} className="btn btn-danger">Post Comment </Button>

                                <Button onClick={this.CommentsModalOpen} className="btn btn-info">View Comments </Button>


                            </Col>

                            <Col lg={4} md={4} xl={4} xxl={4} sm={12} xs={12}>

                            </Col>
                        </Row>
                    </Container>

                </section>


                <Modal show={this.state.ReviewModal} onHide={this.ReviewModalClose}>
                    <div className='comment'>
                        <Modal.Header closeButton>
                            <h6><i className="fa fa-bell"></i> Post Your Comment     </h6>
                        </Modal.Header>
                        <Modal.Body>


                            <h3>{this.state.blog_name}</h3>
                            <Form id="commentForm" onSubmit={this.PostComment}>

                                <div className='form-group'><input onChange={this.nameOnChange} className="form-control bg-white" type="text" placeholder="Enter Name" /></div>
                                <div className='form-group'><input onChange={this.emailOnChange} className="form-control bg-white" type="email" placeholder="Enter Email" /></div>

                                <div className='form-group'><input onChange={this.blogOnChange} hidden value={this.state.blog_name} className="form-control bg-white" readOnly type="text" placeholder="Enter Blog" /></div>
                                <div className='form-group'><input onChange={this.urlOnChange} hidden value={this.state.blog_url} className="form-control bg-white" readOnly type="text" placeholder="Enter Blog Url" /></div>

                                <div className='form-group'><Form.Control onChange={this.messageOnChange} className="form-control bg-white" as="textarea" rows={3} placeholder="Enter Your Message" /></div>
                                <Button id="commentBtn" type='submit' className="submit-btn"> Send Message</Button>

                            </Form>

                            <ToastContainer />

                        </Modal.Body>
                    </div>
                </Modal>

                <Modal show={this.state.CommentsModal} onHide={this.CommentsModalClose}>
                    <div className='comment'>
                        <Modal.Header closeButton>
                            <h6><i className="fa fa-bell"></i>  Your Comments     </h6>
                        </Modal.Header>
                        <Modal.Body>

                            {CommentsView}

                        </Modal.Body>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

export default BlogDetails
