import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import AppURL from '../../api/AppURL';
import axios from 'axios';


class News extends Component {
    constructor() {
        super();
        this.state = {
            newsData: [],

        }
    }
    componentDidMount() {
        axios.post(AppURL.GetNews).then(response => {

            this.setState({ newsData: response.data['news'] });

        }).catch(error => {

        });
    }


    render() {

        const NewsList = this.state.newsData;
        if (NewsList.length > 0) {
            const NewsView = NewsList.map((NewsList, i) => {
                return <div>
                    <span className='text-white'><div dangerouslySetInnerHTML={{ __html: NewsList.news_text }} /></span >
                </div >

            })



            return (
                <Fragment>
                    <div className="bg-news-color">
                        <Container>
                            <Row>
                                <Col lg={2} md={3} xl={2} xxl={2} sm={3} xs={4}><span className="blink-text">Latest News :</span></Col>
                                <Col lg={7} md={6} xl={7} xxl={7} sm={5} xs={5}>

                                    <div className="marquee">
                                        <marquee direction="left" speed={400}>
                                            {NewsView}
                                        </marquee>
                                    </div>

                                </Col>
                                <Col lg={3} md={3} xl={3} xxl={3} sm={4} xs={3} className="text-right">
                                    <div id="google_translate_element">  </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fragment >
            )
        }
        else {

        };
    }
}

export default News
