import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import heading from '../../assets/images/heading-line.png';
import DesktopPageFaqs from '../desktop/DesktopPageFaqs';
import MobilePageFaqs from '../mobile/MobilePageFaqs';
import AppURL from '../../api/AppURL';
import axios from 'axios';
import HeadingsLoading from '../../components/PlaceHolder/HeadingsLoading';
import BlockImagesLoading from '../../components/PlaceHolder/BlockImagesLoading';

class PageFaqs extends Component {

    constructor() {
        super();
        this.state = {
            HeadingsData: [],
            BlockImagesData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHeadings("Faqs")).then(response => {

            this.setState({ HeadingsData: response.data['Headings'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetBlockImages("Faqs")).then(response => {

            this.setState({ BlockImagesData: response.data['Block Images'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticSeo("Faqs")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],
                    seo_description: JsonData['seo_description'],
                    seo_keywords: JsonData['seo_keywords'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }

    render() {

        const HeadingsList = this.state.HeadingsData;
        const HeadingsView = HeadingsList.map((HeadingsList, i) => {
            return <div>
                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className="text-center">
                    <h1>{HeadingsList.headings_name}</h1>
                    <img src={heading} alt="" className="img-fluid" />
                    <div dangerouslySetInnerHTML={{ __html: HeadingsList.headings_text }} />
                </Col>
            </div >

        })

        const BlockImagesList = this.state.BlockImagesData;
        const BlockImagesView = BlockImagesList.map((BlockImagesList, i) => {
            return <div key={i.toString()}>

                <img src={BlockImagesList.blockimages_image} alt="" className="img-fluid" />

            </div >

        })

        return (
            <Fragment>
                <Helmet>
                    <title>{this.state.seo_title}</title>
                    <meta name="description" content={this.state.seo_description} />
                    <meta name="keywords" content={this.state.seo_keywords} />
                    <link rel="canonical" href={this.state.page_name} />
                </Helmet>
                <section className='HomeFaqs'>
                    <Container>
                        <HeadingsLoading isLoading={this.state.isLoading} />
                        <div className={this.state.mainDiv}>
                            {HeadingsView}
                        </div>

                        <Row className=''>

                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className='Desktop'>
                                    <DesktopPageFaqs />
                                </div>
                                <div className='Mobile'>
                                    <MobilePageFaqs />
                                </div>
                            </Col>



                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }
}

export default PageFaqs
