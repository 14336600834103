import React, { Component } from 'react'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import AppURL from '../../api/AppURL';
import axios from 'axios';

class WebsiteRating extends Component {
    constructor() {
        super();
        this.state = {
            PageData: [],
            mpn: "",
            seo_title: "",
            description: "",
            rating_image: "",
            page_url: "",
            reviewCount: "",
            ratingValue: "",
            bestRating: "",
            sku: "",
            name: "",
            page_name: ""

        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;

        axios.post(AppURL.GetRating(page_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Rating'])[0];
                this.setState({
                    mpn: JsonData['mpn'],
                    seo_title: JsonData['seo_title'],
                    description: JsonData['description'],
                    rating_image: JsonData['rating_image'],
                    page_url: JsonData['page_url'],
                    reviewCount: JsonData['reviewCount'],
                    ratingValue: JsonData['ratingValue'],
                    bestRating: JsonData['bestRating'],
                    sku: JsonData['sku'],
                    name: JsonData['name'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        return (
            <Helmet>

                <div itemtype="https://schema.org/" itemscope>
                    <meta itemprop="mpn" content={this.state.mpn} />
                    <meta itemprop="name" content={this.state.seo_title} />
                    <link itemprop="image" href={this.state.rating_image} />
                    <meta itemprop="description" content={this.state.description} />
                    <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
                        <link itemprop="url" href={this.state.page_name} />
                    </div>
                    <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                        <meta itemprop="reviewCount" content={this.state.reviewCount} />
                        <meta itemprop="ratingValue" content={this.state.ratingValue} />
                    </div>
                    <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                        <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                            <meta itemprop="name" content={this.state.name} />
                        </div>
                        <div itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope>
                            <meta itemprop="ratingValue" content={this.state.ratingValue} />
                            <meta itemprop="bestRating" content={this.state.bestRating} />
                        </div>
                    </div>
                    <meta itemprop="sku" content={this.state.sku} />
                    <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
                        <meta itemprop="name" content={this.state.name} />
                    </div>
                </div>

            </Helmet>
        )
    }
}

export default WebsiteRating
