import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

class BreadcrumsbLoading extends Component {
    render() {

        let isLoading = this.props.isLoading;

        return (

            <div className={isLoading}>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="ph-picture"></div>
                        <div className="ph-item">
                            <div className="ph-col-12">
                                <div className="ph-row">
                                    <div className="ph-col-12 small" />
                                    <div className="ph-col-12 small" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>


        )
    }
}

export default BreadcrumsbLoading
