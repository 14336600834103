import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import heading from '../../../assets/images/heading-line.png';
import HeadingsLoading from '../../PlaceHolder/HeadingsLoading';
import HomeAboutUsLoading from '../../PlaceHolder/HomeAboutUsLoading';

class HomeAboutUs extends Component {

    constructor() {
        super();
        this.state = {
            HeadingsData: [],
            HomeAboutData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHeadings("About Us")).then(response => {

            this.setState({ HeadingsData: response.data['Headings'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetHomeAbout).then(response => {

            this.setState({ HomeAboutData: response.data['Home About'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {
        const HeadingsList = this.state.HeadingsData;


        const HeadingsView = HeadingsList.map((HeadingsList, i) => {
            return <div key={i.toString()}>
                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className="text-center">
                    <h1>{HeadingsList.headings_name}</h1>
                    <img src={heading} alt="" className="img-fluid" />
                    <span className='padding-lr'><div dangerouslySetInnerHTML={{ __html: HeadingsList.headings_text }} /></span>
                </Col>
            </div >

        })

        const HomeAboutList = this.state.HomeAboutData;
        if (HomeAboutList.length > 0) {
            const HomeAboutView = HomeAboutList.map((HomeAboutList, i) => {
                return <Row className="align-items-center">
                    <Col lg={6} md={6} xl={6} xxl={6} sm={12} xs={12}>
                        <img src={HomeAboutList.about_image} alt="" className="img-fluid rounded" />
                    </Col>

                    <Col lg={6} md={6} xl={6} xxl={6} sm={12} xs={12}>
                        <h2>{HomeAboutList.about_name}</h2>
                        <div dangerouslySetInnerHTML={{ __html: HomeAboutList.about_text }} className="mr-bottom-25" />
                        <Link to={HomeAboutList.page_name} className="btn_three">{HomeAboutList.button_name}</Link>
                    </Col>

                </Row>

            })
            return (
                <Fragment>
                    <section className="about_us">
                        <Container>


                            <HeadingsLoading isLoading={this.state.isLoading} />
                            <div className={this.state.mainDiv}>
                                {HeadingsView}
                            </div>
                            <HomeAboutUsLoading isLoading={this.state.isLoading} />
                            <div className={this.state.mainDiv}>
                                {HomeAboutView}
                            </div>

                        </Container>
                    </section>
                </Fragment>

            )
        }
        else {

        };
    }
}

export default HomeAboutUs
