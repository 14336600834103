import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeFaqsLoading from '../../PlaceHolder/HomeFaqsLoading';

class MobileHomeFaqs extends Component {

    constructor() {
        super();
        this.state = {
            HomeFaqsData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeFaqs).then(response => {

            this.setState({ HomeFaqsData: response.data['Home Faqs'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const HomeFaqsList = this.state.HomeFaqsData;
        const HomeFaqsView = HomeFaqsList.map((HomeFaqsList, i) => {
            return <Col xl={12} lg={12} md={12} sm={12} xs={12} key={i.toString()}>
                <Accordion.Item eventKey={HomeFaqsList.id}>
                    <Accordion.Header><h4>{HomeFaqsList.faqs_name}</h4></Accordion.Header>
                    <Accordion.Body>
                        <div className='table'>
                            <div dangerouslySetInnerHTML={{ __html: HomeFaqsList.faqs_text }} />
                        </div>

                    </Accordion.Body>
                </Accordion.Item>
            </Col>




        })

        return (
            <Fragment>
                <Container>
                    <Row className="align-items-center">
                        <HomeFaqsLoading isLoading={this.state.isLoading} />
                        <Row className={this.state.mainDiv}>
                            <Accordion defaultActiveKey="0">
                                {HomeFaqsView}
                            </Accordion>
                        </Row>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default MobileHomeFaqs
