import React, { Component } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'

class HomeAboutUsLoading extends Component {
    render() {
        let isLoading = this.props.isLoading;
        return (
            <div className={isLoading}>

                <Container className='text-center' fluid={true}>

                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12">

                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small" />
                                        <div className="ph-col-12 small" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Container>

            </div>
        )
    }
}

export default HomeAboutUsLoading
