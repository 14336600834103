import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/autoplay";
// import required modules
import { Navigation } from "swiper";
import SliderLoading from '../../PlaceHolder/SliderLoading';

import AppURL from '../../../api/AppURL';
import axios from 'axios';
import { Link } from 'react-router-dom';

class HomeSlider extends Component {

    constructor() {
        super();
        this.state = {
            SliderData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetSlider).then(response => {

            this.setState({ SliderData: response.data['Slider'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });
    }


    render() {
        SwiperCore.use([Autoplay]);

        const SliderList = this.state.SliderData;
        if (SliderList.length > 0) {

            const SliderView = SliderList.map((SliderList, i) => {

                if (SliderList.slider_title === null) {

                    return <div key={i.toString()}>

                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="slide-image"><img src={SliderList.slider_image} className="img-fluid" alt={SliderList.slider_title} /></div>                      </div>
                        </SwiperSlide>
                    </div>

                }

                else if (SliderList.slider_text === null) {

                    return <div key={i.toString()}>

                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="slide-image"><img src={SliderList.slider_image} className="img-fluid" alt={SliderList.slider_title} /></div>
                                <Container>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="swiper-content">
                                                <h2>{SliderList.slider_title}</h2>
                                                <Link to={SliderList.page_url} className="btn_two">{SliderList.slider_button}</Link>
                                            </div>
                                            <div class="overlay"></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </SwiperSlide>
                    </div>
                }


                else if (SliderList.slider_text !== null) {

                    return <div key={i.toString()}>

                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="slide-image"><img src={SliderList.slider_image} className="img-fluid" alt={SliderList.slider_title} /></div>
                                <Container>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="swiper-content">
                                                <h2>{SliderList.slider_title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: SliderList.slider_text }} />
                                                <Link to={SliderList.page_url} className="btn_two">{SliderList.slider_button}</Link>
                                            </div>
                                            <div class="overlay"></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </SwiperSlide>
                    </div>
                }


            });


            return (
                <>
                    <Fragment>
                        <SliderLoading isLoading={this.state.isLoading} />
                        <div className={this.state.mainDiv}>
                            <section className="slider">
                                <Swiper navigation={true} modules={[Navigation, Autoplay]} className="mySwiper" autoplay={{
                                    "delay": 2500,
                                    "disableOnInteraction": false
                                }}>


                                    {SliderView}

                                </Swiper>
                            </section>
                        </div>
                    </Fragment >
                </>
            )

        }
        else {

        };
    }
}

export default HomeSlider
