import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageDownloads extends Component {
    constructor() {
        super();
        this.state = {
            PageData: []


        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageDownloads(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Downloads'] });

        }).catch(error => {

        });

    }
    render() {
        let PageDownloadsList = this.state.PageData;

        if (PageDownloadsList.length > 0) {
            const PageDownloadsView = PageDownloadsList.map((PageDownloadsList, i) => {

                return <li key={i.toString()}>
                    <h4><a href={`${PageDownloadsList.downloads_file}`} target="_blank">{PageDownloadsList.downloads_name}</a></h4>
                </li>
            })


            return (
                <Fragment>

                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                            {PageDownloadsView}
                        </Col>
                    </Row>




                </Fragment>
            )
        }
        else {

        };
    }
}

export default PageDownloads
