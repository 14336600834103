import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import HomeTestimonialLoading from '../../components/PlaceHolder/HomeTestimonialLoading';

class DesktopPageTestimonial extends Component {
    constructor() {
        super();
        this.state = {
            HomeTestimonialData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeTestimonial).then(response => {

            this.setState({ HomeTestimonialData: response.data['Testimonial'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }
    render() {

        const HomeTestimonialList = this.state.HomeTestimonialData;
        const HomeTestimonialView = HomeTestimonialList.map((HomeTestimonialList, i) => {

            if (HomeTestimonialList.testimonial_rating === "1") {

                return <Row className="align-items-center" key={i.toString()}>

                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="HomeTestimonial-box-1">
                            <div className="HomeTestimonial-inner-box">
                                <div className="rating">
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                </div>
                                <div className="text"><div dangerouslySetInnerHTML={{ __html: HomeTestimonialList.testimonial_text }} /></div>
                                <div className="image"><div className="image-wrapper"><img src={HomeTestimonialList.testimonial_image} alt="" className="img-fluid rounded-circle" /></div></div>
                                <h4>{HomeTestimonialList.testimonial_name}</h4>
                                <div className="designation">{HomeTestimonialList.testimonial_designation}</div>
                                <div className="border-shape"><div className="quote-icon"><span className="flaticon-right-quote-1"></span></div></div>
                            </div>
                        </div>
                    </Col>


                </Row>

            }

            else if (HomeTestimonialList.testimonial_rating === "2") {

                return <Row className="align-items-center" key={i.toString()}>

                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="HomeTestimonial-box-1">
                            <div className="HomeTestimonial-inner-box">
                                <div className="rating">
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                </div>
                                <div className="text"><div dangerouslySetInnerHTML={{ __html: HomeTestimonialList.testimonial_text }} /></div>
                                <div className="image"><div className="image-wrapper"><img src={HomeTestimonialList.testimonial_image} alt="" className="img-fluid rounded-circle" /></div></div>
                                <h4>{HomeTestimonialList.testimonial_name}</h4>
                                <div className="designation">{HomeTestimonialList.testimonial_designation}</div>
                                <div className="border-shape"><div className="quote-icon"><span className="flaticon-right-quote-1"></span></div></div>
                            </div>
                        </div>
                    </Col>


                </Row>


            }
            else if (HomeTestimonialList.testimonial_rating === "3") {

                return <Row className="align-items-center" key={i.toString()}>

                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="HomeTestimonial-box-1">
                            <div className="HomeTestimonial-inner-box">
                                <div className="rating">
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                </div>
                                <div className="text"><div dangerouslySetInnerHTML={{ __html: HomeTestimonialList.testimonial_text }} /></div>
                                <div className="image"><div className="image-wrapper"><img src={HomeTestimonialList.testimonial_image} alt="" className="img-fluid rounded-circle" /></div></div>
                                <h4>{HomeTestimonialList.testimonial_name}</h4>
                                <div className="designation">{HomeTestimonialList.testimonial_designation}</div>
                                <div className="border-shape"><div className="quote-icon"><span className="flaticon-right-quote-1"></span></div></div>
                            </div>
                        </div>
                    </Col>


                </Row>


            }

            else if (HomeTestimonialList.testimonial_rating === "4") {

                return <Row className="align-items-center" key={i.toString()}>

                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="HomeTestimonial-box-1">
                            <div className="HomeTestimonial-inner-box">
                                <div className="rating">
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-light-white"><i class="fa fa-star"></i></span>
                                </div>
                                <div className="text"><div dangerouslySetInnerHTML={{ __html: HomeTestimonialList.testimonial_text }} /></div>
                                <div className="image"><div className="image-wrapper"><img src={HomeTestimonialList.testimonial_image} alt="" className="img-fluid rounded-circle" /></div></div>
                                <h4>{HomeTestimonialList.testimonial_name}</h4>
                                <div className="designation">{HomeTestimonialList.testimonial_designation}</div>
                                <div className="border-shape"><div className="quote-icon"><span className="flaticon-right-quote-1"></span></div></div>
                            </div>
                        </div>
                    </Col>


                </Row>


            }
            else if (HomeTestimonialList.testimonial_rating === "5") {

                return <Row className="align-items-center" key={i.toString()}>

                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="HomeTestimonial-box-1">
                            <div className="HomeTestimonial-inner-box">
                                <div className="rating">
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                    <span className="text-yellow"><i class="fa fa-star"></i></span>
                                </div>
                                <div className="text"><div dangerouslySetInnerHTML={{ __html: HomeTestimonialList.testimonial_text }} /></div>
                                <div className="image"><div className="image-wrapper"><img src={HomeTestimonialList.testimonial_image} alt="" className="img-fluid rounded-circle" /></div></div>
                                <h4>{HomeTestimonialList.testimonial_name}</h4>
                                <div className="designation">{HomeTestimonialList.testimonial_designation}</div>
                                <div className="border-shape"><div className="quote-icon"><span className="flaticon-right-quote-1"></span></div></div>
                            </div>
                        </div>
                    </Col>


                </Row>


            }

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeTestimonialLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeTestimonialView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default DesktopPageTestimonial
