import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeVideoLoading from '../../PlaceHolder/HomeVideoLoading';

class DesktopHomeVideo extends Component {
    constructor() {
        super();
        this.state = {
            HomeVideoData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeVideo).then(response => {

            this.setState({ HomeVideoData: response.data['Video'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }
    render() {

        const HomeVideoList = this.state.HomeVideoData;
        const HomeVideoView = HomeVideoList.map((HomeVideoList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={3} xs={12} className='text-center'>
                    <div className='HomeVideo-box-1'>
                        <span className="HomeVideo-inner-box">

                            <iframe
                                width="100%"
                                height="200px"
                                src={`https://www.youtube.com/embed/${HomeVideoList.video_link}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />

                        </span>


                        <div>
                            <h5>{HomeVideoList.video_name}</h5>
                        </div>
                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeVideoLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeVideoView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default DesktopHomeVideo
