import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageVideo extends Component {
    constructor() {
        super();
        this.state = {
            PageData: []


        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageVideo(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Video'] });

        }).catch(error => {

        });

    }
    render() {
        let PageVideoList = this.state.PageData;

        if (PageVideoList.length > 0) {
            const PageVideoView = PageVideoList.map((PageVideoList, i) => {

                return <Col lg={4} md={4} xl={4} xxl={4} sm={12} xs={12} key={i.toString()}>
                    <iframe
                        width="100%"
                        height="200px"
                        src={`https://www.youtube.com/embed/${PageVideoList.video_link}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                    <h4><a href={`https://www.youtube.com/embed/${PageVideoList.video_link}`} target="_blank">{PageVideoList.video_name}</a></h4>
                </Col>
            })


            return (
                <Fragment>

                    <Row>
                        {PageVideoView}

                    </Row>

                </Fragment>
            )
        }
        else {

        };
    }
}

export default PageVideo
