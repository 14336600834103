import axios from 'axios';
import React, { Component } from 'react'
import { Fragment } from 'react'
import DesktopDropdown from './DesktopDropdown'
import AppURL from '../../../api/AppURL';

class DesktopMenu extends Component {

    constructor() {
        super();
        this.state = {
            MenuData: []
        }
    }

    componentDidMount() {

        axios.post(AppURL.GetMenu).then(response => {
            this.setState({ MenuData: response.data['Menu'] });

        }).catch(error => {

        });
    }


    render() {
        return (
            <Fragment>
                <nav id="menu" role="navigation" className="menu">
                    <ul>
                        <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                        <DesktopDropdown data={this.state.MenuData} />

                        <li><a href={process.env.PUBLIC_URL + '/'}>Blog</a></li>

                        <li><a href={process.env.PUBLIC_URL + '/contact-us'}>Contact Us</a></li>
                    </ul>
                </nav>
            </Fragment>
        )
    }
}

export default DesktopMenu
