import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';

class LatestNewsDetails extends Component {
    constructor({ match }) {
        super();
        this.state = {
            latestnews_url: match.params.latestnews_url,
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {

        axios.post(AppURL.GetHomeLatestNews).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Latest News'])[0];
                this.setState({
                    latestnews_name: JsonData['latestnews_name'],
                    latestnews_image: JsonData['latestnews_image'],
                    latestnews_text: JsonData['latestnews_text'],
                    latestnews_time: JsonData['latestnews_time']

                });
            }

        }).catch(error => {

        });


    }

    render() {

        return (
            <Fragment>



                <Breadcrumb className="page-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="content-box">
                                    <div className="content-wrapper">
                                        <div className="title">
                                            <h1>{this.state.latestnews_name}</h1>
                                            <p>{this.state.latestnews_time}</p>
                                        </div>
                                        <ul className="bread-crumb">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home </a></li>
                                            <li className="active">{this.state.latestnews_name}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>


                <section className='inner-pages-details'>
                    <Container>
                        <Row>
                            <Col lg={8} md={8} xl={8} xxl={8} sm={12} xs={12}>
                                <img src={this.state.latestnews_image} alt="" className='img-fluid' />
                                <div className="post-meta">
                                    <span className="ttm-meta-line"><i className="fa fa-clock" />Posted On: {this.state.latestnews_time} </span>

                                </div>

                                <h1>{this.state.latestnews_name}</h1>

                                <div dangerouslySetInnerHTML={{ __html: this.state.latestnews_text }} />

                            </Col>

                            <Col lg={4} md={4} xl={4} xxl={4} sm={12} xs={12}>

                            </Col>
                        </Row>
                    </Container>

                </section>

            </Fragment>
        )
    }
}

export default LatestNewsDetails
