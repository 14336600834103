import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';
import BreadcrumsbLoading from '../components/PlaceHolder/BreadcrumsbLoading';
import PageBlog from './main/PageBlog';

export class Blog extends Component {
    constructor() {
        super();
        this.state = {
            BreadcrumbsData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetBreadcrumbs("Blog")).then(response => {

            this.setState({ BreadcrumbsData: response.data['Breadcrumbs'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const BreadcrumbsList = this.state.BreadcrumbsData;
        const BreadcrumbsView = BreadcrumbsList.map((BreadcrumbsList, i) => {
            return <div key={i.toString()}>
                <Breadcrumb className="page-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="content-box">
                                    <div className="content-wrapper">
                                        <div className="title">
                                            <h1>{BreadcrumbsList.breadcrumbs_title}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: BreadcrumbsList.breadcrumbs_text }} />
                                        </div>
                                        <ul className="bread-crumb">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home </a></li>
                                            <li className="active">{BreadcrumbsList.breadcrumbs_title}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>
            </div >

        })

        return (
            <Fragment>
                <BreadcrumsbLoading isLoading={this.state.isLoading} />
                <div className={this.state.mainDiv}>
                    {BreadcrumbsView}
                </div>

                <section className='inner-pages'>
                    <Container>
                        <Row>
                            <PageBlog />
                        </Row>
                    </Container>

                </section>
            </Fragment >
        )
    }
}

export default Blog
