import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeFeaturesLoading from '../../PlaceHolder/HomeFeaturesLoading';

class MobileHomeFeatures extends Component {
    constructor() {
        super();
        this.state = {
            HomeFeaturesData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeFeatures).then(response => {

            this.setState({ HomeFeaturesData: response.data['Home Features'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }
    render() {

        const HomeFeaturesList = this.state.HomeFeaturesData;
        const HomeFeaturesView = HomeFeaturesList.map((HomeFeaturesList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center'>
                    <div className='HomeFeatures-box-1'>
                        <span className="HomeFeatures-inner-box">
                            <img src={HomeFeaturesList.services_image} alt="" className="img-fluid rounded" />
                        </span>
                        <div>
                            <h5>{HomeFeaturesList.services_name}</h5>
                            <div dangerouslySetInnerHTML={{ __html: HomeFeaturesList.services_text }} />
                        </div>
                        <a href={HomeFeaturesList.page_name} className="btn_one">{HomeFeaturesList.button_name}</a>

                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeFeaturesLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeFeaturesView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default MobileHomeFeatures
