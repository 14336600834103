import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageFaqs extends Component {
    constructor() {
        super();
        this.state = {
            PageData: []


        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageFaqs(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Faqs'] });

        }).catch(error => {

        });

    }
    render() {
        let PageFaqsList = this.state.PageData;

        if (PageFaqsList.length > 0) {
            const PageFaqsView = PageFaqsList.map((PageFaqsList, i) => {

                return <Col key={i.toString()} xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Accordion.Item eventKey={PageFaqsList.id}>
                        <Accordion.Header>{PageFaqsList.faqs_name}</Accordion.Header>
                        <Accordion.Body>
                            <div dangerouslySetInnerHTML={{ __html: PageFaqsList.faqs_text }} />

                        </Accordion.Body>
                    </Accordion.Item>
                </Col>
            })


            return (
                <Fragment>
                    <div className='faq'>
                        <Row>
                            <Accordion defaultActiveKey="0">
                                {PageFaqsView}

                            </Accordion>
                        </Row>
                    </div>

                </Fragment>
            )
        }
        else {

        };
    }
}
export default PageFaqs
