import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeLatestNewsLoading from '../../PlaceHolder/HomeLatestNewsLoading';

class DesktopHomeLatestNews extends Component {
    constructor() {
        super();
        this.state = {
            HomeLatestNewsData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeLatestNews).then(response => {

            this.setState({ HomeLatestNewsData: response.data['Latest News'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }
    render() {

        const HomeLatestNewsList = this.state.HomeLatestNewsData;
        const HomeLatestNewsView = HomeLatestNewsList.map((HomeLatestNewsList, i) => {
            return <Col lg={4} md={4} xl={4} xxl={4} sm={3} xs={6} className='text-center' key={i.toString()}>
                <div className="HomeLatestNews-imagebox HomeLatestNews-imagebox-blog">
                    <div className="HomeLatestNews-thumbnail">
                        <img src={HomeLatestNewsList.latestnews_image} alt="" className="img-fluid rounded" />
                        <div className="ttm-blog-overlay-iconbox">
                            <a href={process.env.PUBLIC_URL + '/'}><i className="fa fa-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                    </div>
                    <div className="HomeLatestNews-content">
                        <div className="ttm-box-post-date">
                            <span className="ttm-entry-date">
                                <time className="entry-date" dateTime={HomeLatestNewsList.latestnews_time}><span className="entry-month entry-year">{HomeLatestNewsList.latestnews_time}</span></time>
                            </span>
                        </div>
                        <div className="HomeLatestNews-title">
                            <h5><a href={process.env.PUBLIC_URL + '/'}>{HomeLatestNewsList.latestnews_name}</a></h5>
                        </div>

                        <div className="HomeLatestNews-desc">
                            <div dangerouslySetInnerHTML={{ __html: HomeLatestNewsList.latestnews_text }} />
                        </div>
                        <a className="btn_three" href={"/news/" + HomeLatestNewsList.latestnews_url}>{HomeLatestNewsList.button_name}<i className="fa fa-angle-double-right" /></a>
                    </div>
                </div>
            </Col>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeLatestNewsLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeLatestNewsView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default DesktopHomeLatestNews
