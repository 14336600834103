import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Logo from './Logo';

class LogoWithNav extends Component {
    render() {
        return (
            <Fragment>

                <Container>
                    <Row>
                        <Col lg={3} md={3} sm={6} xs={6}>
                            <Logo />
                        </Col>
                        <Col lg={9} md={9} sm={6} xs={6}>

                            <Menu />


                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default LogoWithNav
