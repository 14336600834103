import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';

class Phone extends Component {
    constructor(props) {
        super();
        this.state = {
            ContactData: []


        }
    }

    componentDidMount() {

        let contact_title = this.props.contact_title;
        axios.post(AppURL.GetPhone(contact_title)).then(response => {

            this.setState({ ContactData: response.data['Phone'] });

        }).catch(error => {

        });

    }
    render() {
        const PhoneList = this.state.ContactData;
        const PhoneView = PhoneList.map((PhoneList, i) => {
            return <div key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                    <div className="contact-address-icon-box">
                        <div className="contact-address-icon">
                            <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                <i className="fa fa-phone"></i>
                            </div>
                        </div>
                        <div className="contact-address-content">
                            <div className="contact-address-title">
                                <h5>{PhoneList.contact_name}</h5>
                            </div>
                            <div className="contact-address-desc">
                                <p><a href={`tel:PhoneList.contact_number`} target="_blank">{PhoneList.contact_number}</a></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
            </div>
        })
        return (
            <Fragment>

                {PhoneView}

            </Fragment>
        )
    }
}

export default Phone
