import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import DesktopNavbarMenu from './menu/DesktopNavbarMenu';
import Logo from './Logo';
import MobileNavbarMenu from './menu/MobileNavbarMenu';

class Menu extends Component {

    render() {
        return (
            <Fragment>
                <div className='menu-bg'>
                    <Container>
                        <Row>

                            <div className="ttm-header-wrap">
                                <div className="ttm-stickable-header clearfix">
                                    <div className="site-header-menu">
                                        <div className="site-navigation">

                                            <div className='DesktopMenu'>
                                                <DesktopNavbarMenu />
                                            </div>

                                            <div className='MobileMenu'>

                                                <MobileNavbarMenu />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>

                </div>
            </Fragment>
        )
    }
}

export default Menu
