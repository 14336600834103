import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import AppURL from '../../api/AppURL';
import axios from 'axios';


class Socialmedia extends Component {

    constructor() {
        super();
        this.state = {
            SocialLinksData: [],

        }
    }
    componentDidMount() {
        axios.post(AppURL.GetSocialLinks).then(response => {

            this.setState({ SocialLinksData: response.data['Social Links'] });

        }).catch(error => {

        });
    }
    render() {

        const SocialLinkList = this.state.SocialLinksData;
        const SocialLinkView = SocialLinkList.map((SocialLinkList, i) => {
            return <li><a href={SocialLinkList.social_url} target="_blank"><span className={SocialLinkList.fonts_class}></span></a></li>



        })

        return (
            <Fragment>
                <ul>
                    {SocialLinkView}
                </ul>

            </Fragment>
        )
    }
}

export default Socialmedia
