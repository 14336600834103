import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';


class Favicon extends Component {
    constructor() {
        super();
        this.state = {
            faviconData: [],

        }
    }
    componentDidMount() {
        axios.post(AppURL.GetFavicon).then(response => {

            this.setState({ faviconData: response.data['favicon'] });

        }).catch(error => {

        });
    }
    render() {
        const FaviconList = this.state.faviconData;
        const FaviconView = FaviconList.map((FaviconList, i) => {
            return <div>

                <link rel="icon" href={FaviconList.favicon_image} />
                <link rel="apple-touch-icon" href={FaviconList.favicon_image} />

            </div >

        })
        return (
            <Fragment>
                {FaviconView}
            </Fragment>
        )
    }
}

export default Favicon
