import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import SuccessProfileLoading from '../../PlaceHolder/SuccessProfileLoading';
import CountUp from 'react-countup';

class DesktopSuccessProfile extends Component {

    constructor() {
        super();
        this.state = {
            SuccessProfileData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetSuccessProfile).then(response => {

            this.setState({ SuccessProfileData: response.data['Success Profile'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const SuccessProfileList = this.state.SuccessProfileData;
        const SuccessProfileView = SuccessProfileList.map((SuccessProfileList, i) => {
            return <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={6} className='text-center' key={i.toString()}>
                <div className='SuccessProfile-box-1'>
                    <span className="SuccessProfile-inner-box">
                        <span className={SuccessProfileList.fonts_class}></span>
                    </span>
                    <div>
                        <h5><CountUp end={SuccessProfileList.success_number} duration={5} /></h5>
                        <h6>{SuccessProfileList.success_name}</h6>
                    </div>

                </div>
            </Col>




        })

        return (
            <Fragment>
                <Container>
                    <Row className="align-items-center">
                        <SuccessProfileLoading isLoading={this.state.isLoading} />
                        <Row className={this.state.mainDiv}>

                            {SuccessProfileView}
                        </Row>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default DesktopSuccessProfile
