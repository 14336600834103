import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ContactDetails from './ContactDetails';
import Socialmedia from './Socialmedia';

class TopHeader extends Component {

    render() {

        return (
            <Fragment>
                <div className='top-nav'>
                    <Container>
                        <Row>
                            <Col lg={6} md={6} xl={6} xxl={6} sm={8} xs={8}>

                                <ContactDetails />

                            </Col>
                            <Col lg={6} md={6} xl={6} xxl={6} sm={4} xs={4} className='text-right'>

                                <Socialmedia />


                            </Col>

                        </Row>
                    </Container>
                </div>
            </Fragment>
        )
    }
}

export default TopHeader
