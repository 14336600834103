import React, { Component } from 'react'
import { Fragment } from 'react';
import MenuBar from '../../../assets/images/icon.webp';
import { Container, Row, Col } from 'react-bootstrap';
import MobileMenu from './MobileMenu';

class MobileNavbarMenu extends Component {

    constructor() {
        super();
        this.state = {
            MenuSideNavState: "menu-side-nav-close",
            MenuOverlayState: "menu-overlay-close"
        }
    }

    toggleCollapse = () => {
        this.MenuSideNavOpenClose();

    }

    MenuOverlayCollapse = () => {
        this.MenuSideNavOpenClose();
    }

    MenuSideNavOpenClose = () => {
        let MenuSideNavState = this.state.MenuSideNavState;
        let MenuOverlayState = this.state.MenuOverlayState;

        if (MenuSideNavState === "menu-side-nav-open") {
            this.setState({ MenuSideNavState: "menu-side-nav-close", MenuOverlayState: "menu-overlay-close" })

        }
        else {
            this.setState({ MenuSideNavState: "menu-side-nav-open", MenuOverlayState: "menu-overlay-open" })
        }


    }



    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <div className="menubar-toggler"><img src={MenuBar} alt="" onClick={this.toggleCollapse} /></div>

                        <div className="mobile-menu">
                            <div className={this.state.MenuSideNavState}>
                                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                    <div className="close-btn text-right"><span className="icon flaticon-remove" onClick={this.toggleCollapse}></span></div>
                                </Col>
                                <MobileMenu />
                            </div>
                            <div onClick={this.MenuOverlayCollapse} className={this.state.MenuOverlayState}></div>

                        </div>
                    </Row>
                </Container>
            </Fragment >
        )
    }
}

export default MobileNavbarMenu
