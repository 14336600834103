import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import heading from '../../../assets/images/heading-line.png';
import DesktopHomeFeatures from '../desktop/DesktopHomeFeatures';
import MobileHomeFeatures from '../mobile/MobileHomeFeatures';
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HeadingsLoading from '../../PlaceHolder/HeadingsLoading';
import BlockImagesLoading from '../../PlaceHolder/BlockImagesLoading';
import HomeForm from '../../forms/HomeForm';

class HomeFeatures extends Component {

    constructor() {
        super();
        this.state = {
            HeadingsData: [],
            BlockImagesData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHeadings("Features")).then(response => {

            this.setState({ HeadingsData: response.data['Headings'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetBlockImages("Features")).then(response => {

            this.setState({ BlockImagesData: response.data['Block Images'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const HeadingsList = this.state.HeadingsData;
        if (HeadingsList.length > 0) {
            const HeadingsView = HeadingsList.map((HeadingsList, i) => {
                return <div key={i.toString()}>
                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className="text-center">
                        <h1>{HeadingsList.headings_name}</h1>
                        <img src={heading} alt="" className="img-fluid" />
                        <div dangerouslySetInnerHTML={{ __html: HeadingsList.headings_text }} className='padding-lr' />
                    </Col>
                </div >

            })

            const BlockImagesList = this.state.BlockImagesData;
            const BlockImagesView = BlockImagesList.map((BlockImagesList, i) => {
                return <div>

                    <img src={BlockImagesList.blockimages_image} alt="" className="img-fluid rounded bg-1" />

                </div >

            })

            return (
                <Fragment>
                    <section className='HomeFeatures'>
                        <Container>
                            <HeadingsLoading isLoading={this.state.isLoading} />
                            <div className={this.state.mainDiv}>
                                {HeadingsView}
                            </div>

                            <Row className='align-items-center'>

                                <Col lg={8} md={8} xl={8} xxl={8} sm={12} xs={12} className="text-center">
                                    <div className='Desktop'>
                                        <DesktopHomeFeatures />
                                    </div>
                                    <div className='Mobile'>
                                        <MobileHomeFeatures />
                                    </div>
                                </Col>

                                <Col lg={4} md={4} xl={4} xxl={4} sm={12} xs={12}>
                                    <HomeForm />

                                </Col>
                            </Row>
                        </Container>
                    </section>

                </Fragment>
            )
        }
        else {

        };
    }
}

export default HomeFeatures
