import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageServices extends Component {
    constructor() {
        super();
        this.state = {
            PageData: []
        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageServices(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Services'] });

        }).catch(error => {

        });

    }
    render() {
        let PageServicesList = this.state.PageData;

        if (PageServicesList.length > 0) {
            const PageServicesView = PageServicesList.map((PageServicesList, i) => {

                return <Col lg={4} md={4} xl={4} xxl={4} sm={4} xs={4} className='text-center' key={i.toString()}>
                    <span className={PageServicesList.fonts_class}></span>
                    <h4>{PageServicesList.services_name}</h4>
                    <div dangerouslySetInnerHTML={{ __html: PageServicesList.services_text }} />
                </Col>
            })


            return (
                <Fragment>

                    <Row>

                        {PageServicesView}


                    </Row>




                </Fragment>
            )
        }
        else {

        };
    }
}


export default PageServices
