import React, { Component, Fragment } from 'react'
import apply from '../../assets/images/app.webp';
import partner from '../../assets/images/part.webp';

class ApplyPosition extends Component {
    render() {
        return (
            <Fragment>
                <div className="app">
                    <ul>
                        <li><a href={process.env.PUBLIC_URL + '/'} ><img src={apply} width="100%" height="100%" className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>
                <div className="partner">
                    <ul>
                        <li><a href={process.env.PUBLIC_URL + '/'} ><img src={partner} width="100%" height="100%" className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default ApplyPosition
