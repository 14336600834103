import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import HomeBlogLoading from '../../components/PlaceHolder/HomeBlogLoading';

class MobilePageBlog extends Component {
    constructor() {
        super();
        this.state = {
            HomeBlogData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeBlog).then(response => {

            this.setState({ HomeBlogData: response.data['Blog'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }
    render() {

        const HomeBlogList = this.state.HomeBlogData;
        const HomeBlogView = HomeBlogList.map((HomeBlogList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center'>
                    <div className="HomeBlog-imagebox HomeBlog-imagebox-blog">
                        <div className="HomeBlog-thumbnail">
                            <img src={HomeBlogList.blog_image} alt="" className="img-fluid rounded" />
                            <div className="ttm-blog-overlay-iconbox">
                                <a href={process.env.PUBLIC_URL + '/'}><i className="fa fa-plus" /></a>
                            </div>
                            <div className="ttm-box-view-overlay" />
                        </div>
                        <div className="HomeBlog-content">
                            <div className="ttm-box-post-date">
                                <span className="ttm-entry-date">
                                    <time className="entry-date" dateTime={HomeBlogList.blog_time}><span className="entry-month entry-year">{HomeBlogList.blog_time}</span></time>
                                </span>
                            </div>
                            <div className="HomeBlog-title">
                                <h5><a href={process.env.PUBLIC_URL + '/'}>{HomeBlogList.blog_name}</a></h5>
                            </div>
                            <div className="post-meta">
                                <span className="ttm-meta-line"><i className="fa fa-comments" />2,Comments</span>
                                <span className="ttm-meta-line"><i className="fa fa-eye" />{HomeBlogList.blog_view} Views</span>
                            </div>
                            <div className="HomeBlog-desc">
                                <div dangerouslySetInnerHTML={{ __html: HomeBlogList.blog_text }} />
                            </div>
                            <a className="btn_three" href={"/blog/" + HomeBlogList.blog_url}>{HomeBlogList.button_name}<i className="fa fa-angle-double-right" /></a>
                        </div>
                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeBlogLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeBlogView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default MobilePageBlog
