import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import LogoLoading from '../PlaceHolder/LogoLoading';


class Logo extends Component {
    constructor() {
        super();
        this.state = {
            logoData: [],
            isLoading: "",
            mainDiv: "d-none"

        }
    }
    componentDidMount() {
        axios.post(AppURL.GetLogo).then(response => {

            this.setState({ logoData: response.data['logo'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });
    }

    render() {

        const LogoList = this.state.logoData;
        const LogoView = LogoList.map((LogoList, i) => {
            return <div>
                <a href={process.env.PUBLIC_URL + '/'}><img className="img-fluid" src={LogoList.logo_image} alt={LogoList.logo_title} /></a>
            </div >

        })

        return (
            <Fragment>
                <LogoLoading isLoading={this.state.isLoading} />
                <div className={this.state.mainDiv}>
                    <div className="logo">{LogoView}</div>
                </div>
            </Fragment>
        )
    }
}

export default Logo
