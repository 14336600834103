import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';
import Whatsapp from './Whatsapp';
import Phone from './Phone';
import Email from './Email';

class ContactAddress extends Component {
    constructor(props) {
        super();
        this.state = {
            ContactData: []


        }
    }

    componentDidMount() {

        axios.post(AppURL.GetContactUs).then(response => {

            this.setState({ ContactData: response.data['Contact'] });

        }).catch(error => {

        });

    }
    render() {
        const ContactList = this.state.ContactData;
        const ContactView = ContactList.map((ContactList, i) => {
            return <div key={i.toString()}>

                <div className="section-title with-desc clearfix">
                    <div className="title-header">
                        <h5>Come Visit Us At</h5>
                        <h6 className="title">{ContactList.contact_title}</h6>
                    </div>
                </div>


                <Row>
                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="contact-address-icon-box">
                            <div className="contact-address-icon">
                                <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                    <i className="fa fa-map"></i>
                                </div>
                            </div>
                            <div className="contact-address-content">
                                <div className="contact-address-title">
                                    <h5>Office Address</h5>
                                </div>
                                <div className="contact-address-desc">
                                    <div dangerouslySetInnerHTML={{ __html: ContactList.contact_address }} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>

                    <Phone data={this.state.contact_title} />
                    <Email data={this.state.contact_title} />
                    <Whatsapp data={this.state.contact_title} />


                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="contact-address-icon-box">
                            <div className="contact-address-icon">
                                <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                    <i className="fa fa-calendar"></i>
                                </div>
                            </div>
                            <div className="contact-address-content">
                                <div className="contact-address-title">
                                    <h5>Working Days</h5>
                                </div>
                                <div className="contact-address-desc">
                                    <p>{ContactList.contact_days}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>


                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                        <div className="contact-address-icon-box">
                            <div className="contact-address-icon">
                                <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                    <i className="fa fa-clock"></i>
                                </div>
                            </div>
                            <div className="contact-address-content">
                                <div className="contact-address-title">
                                    <h5>Working Hours</h5>
                                </div>
                                <div className="contact-address-desc">
                                    <p>{ContactList.contact_time}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
                </Row>

            </div>
        })
        return (
            <Fragment>

                {ContactView}


            </Fragment>
        )
    }
}

export default ContactAddress
