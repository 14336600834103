import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';

class Email extends Component {
    constructor(props) {
        super();
        this.state = {
            ContactData: []


        }
    }

    componentDidMount() {

        let contact_title = this.props.contact_title;
        axios.post(AppURL.GetEmail(contact_title)).then(response => {

            this.setState({ ContactData: response.data['Email'] });

        }).catch(error => {

        });

    }
    render() {
        const EmailList = this.state.ContactData;
        const EmailView = EmailList.map((EmailList, i) => {
            return <div key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                    <div className="contact-address-icon-box">
                        <div className="contact-address-icon">
                            <div className="contact-address-icon contact-address-skincolor rounded-circle">
                                <i className="fas fa-envelope"></i>
                            </div>
                        </div>
                        <div className="contact-address-content">
                            <div className="contact-address-title">
                                <h5>{EmailList.contact_name}</h5>
                            </div>
                            <div className="contact-address-desc">
                                <p><a href={`mailto:EmailList.contact_email`} target="_blank">{EmailList.contact_email}</a></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
            </div>
        })
        return (
            <Fragment>

                {EmailView}

            </Fragment>
        )
    }
}
export default Email
