import React, { Component } from 'react'
import { Fragment } from 'react'
import DesktopMenu from './DesktopMenu';

class DesktopNavbarMenu extends Component {

    render() {
        return (
            <Fragment>

                <DesktopMenu />

            </Fragment>
        )
    }
}

export default DesktopNavbarMenu
