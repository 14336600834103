import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import HomeGalleryLoading from '../../components/PlaceHolder/HomeGalleryLoading';




class DesktopPageGallery extends Component {

    constructor() {
        super();
        this.state = {
            HomeGalleryData: [],
            photoIndex: 0,
            isOpen: false,
            isLoading: "",
            mainDiv: "d-none"

        }
    }


    componentDidMount() {
        axios.post(AppURL.GetHomeGallery).then(response => {

            this.setState({ HomeGalleryData: response.data['Gallery'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {
        const { photoIndex, isOpen } = this.state;
        const HomeGalleryList = this.state.HomeGalleryData;
        const images = HomeGalleryList['gallery_image'];
        const HomeGalleryView = HomeGalleryList.map((HomeGalleryList, i) => {
            return <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={6} className='text-center' key={i.toString()}>
                <div className='HomeGallery-box'>
                    <div className='HomeGallery-box-1'>
                        <div className="HomeGallery-inner-box">
                            <img src={HomeGalleryList.gallery_image} alt="" className="img-fluid rounded" />
                        </div>
                        <div className='HomeGallery-view-overlay'>
                            <div className="HomeGallery-media-link">

                                <a href={HomeGalleryList.gallery_image} onClick={() => this.setState({ isOpen: true })}><i className="fa fa-search" /></a>
                                <a href={process.env.PUBLIC_URL + '/'} className="media-link"><i className="fa fa-link" /></a>
                            </div>
                        </div>

                        <div class="HomeGallery-content">
                            <div class="HomeGallery-title">
                                <h5>{HomeGalleryList.gallery_name}</h5>
                            </div>
                        </div>

                    </div>
                </div>

            </Col >




        })

        return (
            <Fragment>
                <Container>
                    <Row className="align-items-center">
                        <HomeGalleryLoading isLoading={this.state.isLoading} />
                        <Row className={this.state.mainDiv}>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={images[photoIndex]}
                                    nextSrc={images[(photoIndex + 1) % images.length]}
                                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images.length - 1) % images.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images.length,
                                        })
                                    }
                                />
                            )}
                            {HomeGalleryView}

                        </Row>
                    </Row>
                </Container>
            </Fragment >
        )
    }
}

export default DesktopPageGallery
