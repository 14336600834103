import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import heading from '../../../assets/images/heading-line.png';
import DesktopHomeGallery from '../desktop/DesktopHomeGallery';
import MobileHomeGallery from '../mobile/MobileHomeGallery';
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HeadingsLoading from '../../PlaceHolder/HeadingsLoading';

class HomeGallery extends Component {

    constructor() {
        super();
        this.state = {
            HeadingsData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHeadings("Gallery")).then(response => {

            this.setState({ HeadingsData: response.data['Headings'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const HeadingsList = this.state.HeadingsData;
        if (HeadingsList.length > 0) {
            const HeadingsView = HeadingsList.map((HeadingsList, i) => {
                return <div key={i.toString()}>
                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className="text-center">
                        <h1>{HeadingsList.headings_name}</h1>
                        <img src={heading} alt="" className="img-fluid" />
                        <div dangerouslySetInnerHTML={{ __html: HeadingsList.headings_text }} className='padding-lr' />
                    </Col>
                </div >

            })


            return (
                <Fragment>
                    <section className='HomeGallery'>
                        <Container>
                            <HeadingsLoading isLoading={this.state.isLoading} />
                            <div className={this.state.mainDiv}>
                                {HeadingsView}
                            </div>

                            <div className='Desktop'>
                                <DesktopHomeGallery />
                            </div>
                            <div className='Mobile'>
                                <MobileHomeGallery />
                            </div>
                        </Container>
                    </section>

                </Fragment>
            )
        }
        else {

        };
    }
}

export default HomeGallery

