import React, { Component } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';

export class CopyRights extends Component {

    constructor() {
        super();
        this.state = {
            faviconData: [],

        }
    }
    componentDidMount() {
        axios.post(AppURL.GetFavicon).then(response => {

            this.setState({ faviconData: response.data['favicon'] });

        }).catch(error => {

        });
    }

    render() {
        const FaviconList = this.state.faviconData;
        const FaviconView = FaviconList.map((FaviconList, i) => {
            return <span>
                {FaviconList.favicon_title}
            </span>
        });
        return (
            <Fragment>

                <section className="footer-bottom-area">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="copyright-text text-center">
                                    <p>Copyright &copy; {new Date().getFullYear()}  {FaviconView}. All Rights Reserved</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default CopyRights
