import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import wht from '../../assets/images/wht.webp';
import phone from '../../assets/images/phone.webp';
import AppURL from '../../api/AppURL';
import axios from 'axios';

export class ContactPosition extends Component {
    constructor() {
        super();
        this.state = {
            contact_number: "",
            contact_whtsapp: ""

        }

    }

    componentDidMount() {
        axios.post(AppURL.GetPhoneLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Phone'])[0];
                this.setState({
                    contact_number: JsonData['contact_number']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetWhatsappLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Whatsapp'])[0];
                this.setState({
                    contact_whtsapp: JsonData['contact_whtsapp']
                });
            }

        }).catch(error => {

        });


    }


    render() {

        return (
            <Fragment>


                <div className="social1">
                    <ul>
                        <li><a href={`tel:${this.state.contact_number}`} target="_blank"><img src={phone} width="100%" height="100%" className="img-fluid" alt="" /></a></li>
                        <li><a href={`https://wa.me/${this.state.contact_whtsapp}`} target="_blank"><img src={wht} width="100%" height="100%" className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>

            </Fragment>
        )
    }
}

export default ContactPosition
