import React, { Component } from 'react'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import { Breadcrumb, Container, Row, Col, Form, Button } from 'react-bootstrap'
import AppURL from '../api/AppURL';
import axios from 'axios';
import BreadcrumsbLoading from '../components/PlaceHolder/BreadcrumsbLoading';
import validation from '../validation/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactForm from '../components/forms/ContactForm';
import Whatsapp from './Whatsapp';
import ContactAddress from './ContactAddress';


class Contact extends Component {
    constructor() {
        super();
        this.state = {
            BreadcrumbsData: [],
            ContactData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }


    componentDidMount() {
        axios.post(AppURL.GetBreadcrumbs("Contact Us")).then(response => {

            this.setState({ BreadcrumbsData: response.data['Breadcrumbs'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


        axios.post(AppURL.GetContactUsText).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Contact Us'])[0];
                this.setState({
                    contact_text: JsonData['contact_text'],
                    contact_title: JsonData['contact_title'],
                    contact_map: JsonData['contact_map']
                });
            }

        }).catch(error => {

        });


        axios.post(AppURL.GetStaticSeo("Contact Us")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],
                    seo_description: JsonData['seo_description'],
                    seo_keywords: JsonData['seo_keywords'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }

    render() {

        const BreadcrumbsList = this.state.BreadcrumbsData;
        const BreadcrumbsView = BreadcrumbsList.map((BreadcrumbsList, i) => {
            return <div key={i.toString()}>
                <Breadcrumb className="page-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="content-box">
                                    <div className="content-wrapper">
                                        <div className="title">
                                            <h1>{BreadcrumbsList.breadcrumbs_title}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: BreadcrumbsList.breadcrumbs_text }} />
                                        </div>
                                        <ul className="bread-crumb">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home </a></li>
                                            <li className="active">{BreadcrumbsList.breadcrumbs_title}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>
            </div >

        })




        return (
            <Fragment>
                <Helmet>
                    <title>{this.state.seo_title}</title>
                    <meta name="description" content={this.state.seo_description} />
                    <meta name="keywords" content={this.state.seo_keywords} />
                    <link rel="canonical" href={this.state.page_name} />
                </Helmet>
                <BreadcrumsbLoading isLoading={this.state.isLoading} />
                <div className={this.state.mainDiv}>
                    {BreadcrumbsView}
                </div>

                <section className='inner-pages'>
                    <Container>
                        <Row>
                            <Col lg={6} md={6} xl={6} xxl={6} sm={12} xs={12}>
                                <div className='contact-address contact-form'>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.contact_text }} />

                                    <ContactAddress data={this.state.contact_title} />
                                </div>
                            </Col>
                            <Col lg={6} md={6} xl={6} xxl={6} sm={12} xs={12}>

                                <div className='contact-form'>
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Send Message</h5>
                                            <h6 className="title">Drop Us A Line</h6>
                                        </div>
                                    </div>
                                    <ContactForm />
                                </div>
                            </Col>
                        </Row>

                        <Row>


                        </Row>
                    </Container>

                </section>
                <iframe src={this.state.contact_map} width="100%" height="450" allowfullscreen="" loading="lazy" style={{ border: 0 }} referrerpolicy="no-referrer-when-downgrade"></iframe>

            </Fragment >
        )
    }
}

export default Contact
