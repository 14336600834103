import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import DesktopAddress from './address/DesktopAddress';
import MobileAddress from './address/MobileAddress';
import Logo from './Logo';


class LogoWithAddress extends Component {

    render() {
        return (
            <Fragment>
                <Container>
                    <Row>

                        <Col lg={3} md={3} sm={12} xs={12} className="text-center">
                            <Logo />
                        </Col>
                        <Col lg={9} md={9} sm={12} xs={12}>
                            <div className='Desktop'>
                                <DesktopAddress />
                            </div>
                            <div className='Mobile'>
                                <MobileAddress />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default LogoWithAddress
