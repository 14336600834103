import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';

export class ContactDetails extends Component {

    constructor() {
        super();
        this.state = {
            contact_number: "",
            contact_email: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetPhoneLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Phone'])[0];
                this.setState({
                    contact_number: JsonData['contact_number']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetEmailLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Email'])[0];
                this.setState({
                    contact_email: JsonData['contact_email']
                });
            }

        }).catch(error => {

        });
    }

    render() {
        return (
            <Fragment>
                <ul>
                    <li><a href={`tel:${this.state.contact_number}`} target="_blank"><span className="fas fa-phone"></span>  {this.state.contact_number}</a></li>
                    <li><a href={`mailto:${this.state.contact_email}`} target="_blank"><span className="fas fa-envelope"></span> {this.state.contact_email}</a></li>

                </ul>
            </Fragment>
        )
    }
}

export default ContactDetails
