import React, { Component } from 'react'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import AppURL from '../../api/AppURL';
import axios from 'axios';

class SeoTitle extends Component {
    constructor() {
        super();
        this.state = {
            PageData: [],
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""


        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;

        axios.post(AppURL.GetSeo(page_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],
                    seo_description: JsonData['seo_description'],
                    seo_keywords: JsonData['seo_keywords'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        return (
            <Helmet>

                <title>{this.state.seo_title}</title>
                <meta name="description" content={this.state.seo_description} />
                <meta name="keywords" content={this.state.seo_keywords} />
                <link rel="canonical" href={this.state.page_name} />

            </Helmet>
        )
    }
}

export default SeoTitle
